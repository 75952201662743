@import "./base";

.cover-container {
  max-width: $containerWidthDesktop;
  margin: 0 auto;
  padding-left: $paddingDesktopLR;
  padding-right: $paddingDesktopLR;
  background-color: $Blue;
  color: white;
}

.cover-image-box {
  max-width: $containerWidthDesktop;
  display: flex;
  margin: 0 auto;
  background-color: rgb(255, 255, 255);
  max-height: 200px;
  overflow: hidden;
}

.cover-image-box img {
  width: 100%;
  height: auto;
}

.outer-box {
  width: 100%;
  padding: 2rem;
  justify-content: center;
}

.inner-box {
  width: 100%;
  padding: 5rem 5rem 5rem 5rem;
  border: 5px solid rgb(255, 255, 255);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.inner-box p {
  text-align: center;
}

@media screen and (max-width: $minimizedScreen) {
  .cover-container {
    padding-left: $paddingMobileLR;
    padding-right: $paddingMobileLR;
  }
  .outer-box {
    padding: 2rem 0 2rem 0;
  }

  .inner-box {
    padding: 2rem;
  }
}
