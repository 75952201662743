@import "./base";

.mission-container {
  max-width: $containerWidthDesktop;
  margin: 0 auto;
  padding: 2rem 10rem 2rem 10rem;
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.mission-box-1 {
  display: flex;
  flex-direction: column;
}

.mission-text {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.mission-text p {
  margin-left: 10px;
  text-align: start;
}

.mission-box-1 h1 {
  color: $Blue;
}

.mission-box-2 {
  display: flex;
}

.mission-box-2 img {
  height: 300px;
  width: auto;
}

@media screen and (max-width: $minimizedScreen) {
  .mission-container {
    max-width: $containerWidthDesktop;
    margin: 0 auto;
    padding: 2rem;
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column-reverse;
  }
  .mission-container h1 {
    align-self: center;
  }
  .mission-text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
  }
  .mission-text p {
    margin-left: 10px;
    text-align: start;
  }

  .mission-box-2 {
    margin-top: 30px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
  }
  .mission-box-2 img {
    height: 200px;
    width: auto;
  }
}
