@import "./base";
@keyframes slide-in {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translate(-50%, -50%);
  }
}

nav {
  top: 0;
  z-index: 100;
  background: rgb(255, 255, 255);
  border-bottom: 5px solid $Blue;
}
.nav-container {
  max-width: $containerWidthDesktop;
  margin: 0 auto;
  padding-left: $paddingDesktopLR;
  padding-right: $paddingDesktopLR;
}

.nav-center {
  max-width: 1440px;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.nav-header {
  width: 100%;
  display: flex;
}

.nav-flexbox {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.logo-box {
  flex: 1;
  align-items: center;
}

.nav-logo {
  height: 30px;
}

.links {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.links a {
  color: rgb(86, 86, 86);
  padding: 0;
  margin: 0 1rem;
  font-size: 13px;
  font-weight: bold;
  text-decoration: none;
  transition: $transition;
}
.links a:hover {
  color: $Blue;
  background-color: transparent;
}

.flag-logo-box {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
  gap: 30px;
}

.flag-logo {
  width: 20px;
  height: 20px;
  cursor: pointer;
  transition: $transition;
}

.flag-logo:hover {
  opacity: 50%;
}

.nav-mobile {
  display: none;
}

@media screen and (max-width: $minimizedScreen) {
  .nav-center {
    display: none;
  }

  .nav-mobile {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    align-items: center;
  }

  .nav-mobile-box {
    display: flex;
    flex-direction: column;
    width: 95%;
    padding: 10px;
    background-color: $Blue;
    border-radius: 10px;
    border: 1px solid;
    border-color: white;
    text-align: center;
    position: absolute;
    top: 200px;
    left: 50%;
    z-index: 100;
    animation: slide-in 1s forwards;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
  }

  .nav-mobile .nav-logo {
    height: 40px;
    width: auto;
  }

  .nav-mobile-box a {
    color: $Grey;
    padding: 0;
    margin: 0 1rem;
    margin-top: 20px;
    font-weight: bold;
    text-decoration: none;
    color: white;
    transition: $transition;
  }

  .nav-button {
    align-items: center;
    color: $Grey;
    font-size: 25px;
    padding-right: 20px;
    cursor: pointer;
  }

  .underline-inbox {
    width: 280px;
    height: 0.1rem;
    background: white;
    margin: 0 auto;
    margin-top: 20px;
  }

  .flag-logo-box {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0 10px 0;
    flex: 1;
    gap: 30px;
  }
}
