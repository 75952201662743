@import "./base";

.clients-container {
  max-width: $containerWidthDesktop;
  margin: 0 auto;
  padding-left: $paddingDesktopLR;
  padding-right: $paddingDesktopLR;
  padding-bottom: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
}

.clients-logo-area {
  display: flex;
  flex-wrap: wrap;
  padding-top: 2rem;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 40px;
}

.clients-logo {
  max-height: 60px;
  width: auto;
}

@media screen and (max-width: $minimizedScreen) {
  .clients-container {
    margin: 0 auto;
    padding-left: $paddingMobileLR;
    padding-right: $paddingMobileLR;
    padding-bottom: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
  }

  .clients-logo-area {
    display: flex;
    padding-top: 2rem;

    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 40px;
  }

  .clients-logo {
    max-height: 40px;
    width: auto;
  }
}
