$mobileScreen: 400px;
$minimizedScreen: 800px;
$Cyan: hsl(180, 66%, 49%);
$Black: black;
$DarkViolet: hsl(257, 27%, 26%);
$Blue: #003298;
$Grey: #f0f0f0;
$Green: #34e587;
$transition: all 0.2s linear;
$containerWidthDesktop: 1440px;
$paddingDesktopLR: 5rem;
$paddingMobileLR: 1.25rem;

* {
  font-family: "Poppins", sans-serif;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

h1 {
  font-size: 22px;
  letter-spacing: 1px;
}
.h1-nomargin {
  font-size: 22px;
  font-weight: bold;
}

p {
  font-size: 18px;
  letter-spacing: 1px;
}

.underline {
  height: 0.25rem;
  background: $Blue;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  margin-top: 5px;
}

footer {
  background: var(--VeryDarkViolet);
}
