@import "./base";

.service-container {
  max-width: $containerWidthDesktop;
  margin: 0 auto;
  padding-left: $paddingDesktopLR;
  padding-right: $paddingDesktopLR;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
}

.service-box {
  width: 100%;
  margin-bottom: 2rem;
}

.service-innerbox-1 {
  display: flex;
  flex-direction: row;
  height: 350px;
}

.service-innerbox-1-L {
  width: 50%;
  display: flex;
  background-color: $Blue;
  justify-content: center;
  align-items: center;
}

.service-innerbox-1-R {
  width: 50%;
  padding-left: 2rem;
  padding-right: 2rem;
  display: flex;
  background-color: $Grey;
  justify-content: center;
  align-items: center;
}

.service-text {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.service-text p {
  margin-left: 10px;
}

.service-innerbox-2 {
  display: flex;
  flex-direction: row;
  height: 350px;
}
.service-innerbox-2-L {
  width: 50%;
  padding-left: 2rem;
  padding-right: 2rem;
  display: flex;
  background-color: $Grey;
  justify-content: center;
  align-items: center;
}

.service-innerbox-2-R {
  width: 50%;
  display: flex;
  background-color: $Blue;
  justify-content: center;
  align-items: center;
}

.service-flex-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 10px;
}

@media screen and (max-width: $minimizedScreen) {
  .service-container {
    max-width: $containerWidthDesktop;
    margin: 0 auto;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
  }
  .service-innerbox-1 {
    display: flex;
    flex-direction: column;
    height: auto;
    margin-bottom: 30px;
  }
  .service-innerbox-1-L {
    width: 100%;
    padding: 130px 10px 130px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .service-innerbox-1-R {
    width: 100%;
    padding: 2rem;
    min-height: 300px;
    display: flex;
    background-color: $Grey;
    align-items: center;
    justify-content: flex-start;
  }

  .service-innerbox-2 {
    display: flex;
    flex-direction: column-reverse;
    height: auto;
  }
  .service-innerbox-2-L {
    width: 100%;
    padding: 2rem;
    min-height: 300px;
    display: flex;
    background-color: $Grey;
    text-align: flex-start;
    align-items: center;
  }

  .service-innerbox-2-R {
    width: 100%;
    padding: 130px 10px 130px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .service-flex-wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: flex-start;
  }
}
