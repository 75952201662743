.App {
  background-color: #efefef;
  min-height: 100vh;
}

/*
For a row direction flex container:

align-items: center; will center items vertically.
justify-content: center; will center items horizontally.
For a column direction flex container:

align-items: center; will center items horizontally.
justify-content: center; will center items vertically.

*/
