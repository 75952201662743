@import "./base";

.footer-container {
  max-width: $containerWidthDesktop;
  margin: 0 auto;
  padding-left: $paddingDesktopLR;
  padding-right: $paddingDesktopLR;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: white;
  border-top: 5px solid;
  border-color: rgb(0, 149, 255);
  color: rgb(124, 124, 124);
  font-size: 12px;
}
