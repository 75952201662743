@import "./base";

.contact-container {
  max-width: $containerWidthDesktop;
  margin: 0 auto;
  padding-left: $paddingDesktopLR;
  padding-right: $paddingDesktopLR;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: $Blue;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.contact-L {
  width: 50%;
  padding: 0 1rem 0 1rem;
  height: 540px;
}

.contact-R {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contact-head {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;
}

.contact-head p {
  align-items: center;
  color: white;
  font-size: 20px;
  font-weight: bold;
  margin-left: 10px;
}

.contact-box p {
  color: white;
  text-align: center;
}

@media screen and (max-width: $minimizedScreen) {
  .contact-container {
    padding-left: $paddingMobileLR;
    padding-right: $paddingMobileLR;
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  }
  .contact-L {
    width: 100%;
    padding: 1rem 0 1rem 0;
    height: 300px;
  }
  .contact-R {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
